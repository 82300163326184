<template>
  <div class="FinancialOnlineErrors">
    <TheSubHeader 
      icon="money"
      title="Erros do consolidado online"
      page-title-tag="Financial Online Errors"
      type="filter"
      organizer-gap="1rem"
    >
      <template #beforeButtons>
        <AsyncExportButton
          name="cost-errors-online"
          name-report="Relatorio_de_Erros_Consolidado_Online"
          icon="export"        
          color="dark"
          label="Exportar relatório"
          :disabled="!disableExportButton"
          :params="paramsFilter"
          :track="trackSubheaderButtonString('Financial Online Errors', 'Export')"
          label-loading="Exportando relatório"
        />
      </template>
      <template #filter>
        <section 
          class="FinancialOnlineErrors__subheaderTab"
        >
          <BaseDatePicker
            v-model="formattedDateRange"
            label="Intervalo entre datas (max. 15 dias)"
            :max-range="15"
            @input="fetchErrors"
          />

          <BaseTreeSelect
            v-model="filters.message"
            name="Mensagem"
            label="Mensagem"
            placeholder="Digite a mensagem"
            :enable-paste="true"
            :allow-create="true"
            @select="fetchErrors"
            @input="fetchErrors()"
          />

          <BaseTreeSelect
            v-model="filters.field"
            name="Campo"
            label="Campo"
            placeholder="Selecione o campo"
            :select-options="fieldErrorsList"
            @input="fetchErrors()"
          />
        </section>  
      </template>
    </TheSubHeader>

    <!-- Table -->
    <section class="table">
      <DataTable
        :header="onlineErrors.data.headers"
        :table="onlineErrors.data.content"
        :max-size="false"
        @see="seeOrderDetails"
      />

     

      <Pagination @page="fetchErrors" @paginate="fetchErrors">
        <div v-if="onlineErrors.data.append && onlineErrors.data.append.total_errors" class="FinancialOnlineErrors__totalErrors">
          Número total de erros: {{ onlineErrors.data.append.total_errors }}
        </div>  
      </Pagination>
    </section>
  </div>  
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { TheSubHeader } from '@/components/organisms'
import { DataTable, Pagination, AsyncExportButton } from '@/components/molecules'
import { BaseDatePicker, BaseTreeSelect } from '@/components/atoms'

export default {
  name: 'FinancialOnlineErrors',
  components: { 
    TheSubHeader,
    DataTable,
    Pagination,
    BaseDatePicker, 
    BaseTreeSelect,
    AsyncExportButton
  },

  data() {
    return {
      filters: {
        dateRange: {
          init: null,
          end: null
        },
        field: null,
        message: null
      }
    }
  },

  computed: {
    ...mapState({
      onlineErrors: state => state.financial.onlineErrors,
      page: state => state.pagination.page,
      paginate: state => state.pagination.paginate,
      fieldErrorsList: state => state.financial.fieldErrorsList
    }),

    formattedDateRange: {
      get: function() {
        return [this.filters.dateRange.init, this.filters.dateRange.end]
      },
      set: function(newValue) {
        this.filters.dateRange.init = newValue[0]
        this.filters.dateRange.end = newValue[1]
      },
    },

    dateRangeInit() {
      const days = 15
      const dateOffset = (24*60*60*1000) * days
      const calculatedDate = new Date()
      calculatedDate.setTime(calculatedDate.getTime() - dateOffset)
      return calculatedDate.getTime()
    },

    dateRangeEnd() {
      const calculatedDate = new Date()
      const dateNow = new Date(calculatedDate)
      return dateNow.getTime()
    },

    todayParsed() {
      let today = new Date()
      return Date.parse(today)
    },

    paramsFilter() {
      const filters = {
        field: this.filters.field?.name,
        date_start: this.filters.dateRange?.init,
        date_end: this.filters.dateRange?.end,
        'message[value]': this.filters?.message,
        'message[signal]': 'like'
      }

      if (!this.filters.message) {
        delete filters['message[value]']
        delete filters['message[signal]']
      }
      
      return filters
    },

    disableExportButton() {
      return !!(this.filters.dateRange?.init && this.filters.dateRange?.end)
    }
  },

  mounted() {
    this.setInitialDate()
    this.fetchErrors()
    this.fetchFieldErrorsList()
  },

  methods: {
    ...mapActions({
      setLoading: 'loading/setLoading',
      clearPagination: 'pagination/clearPagination',
      fetchOnlineErrors: 'financial/fetchOnlineErrors',
      setPagination: 'pagination/setPagination',
      fetchFieldErrorsList: 'financial/fetchFieldErrorsList'
    }),

    async fetchErrors() {
      const params = {
        filters: {
          field: this.filters.field?.name,
          date_start: this.filters.dateRange.init,
          date_end: this.filters.dateRange.end,
          'message[value]': this.filters.message,
          'message[signal]': 'like'
        },
        page: this.page,
        paginate: this.paginate,
      }

      if (!this.filters.message) delete params.filters['message[signal]']

      await this.fetchOnlineErrors(params)
      this.setPagination(this.onlineErrors)
    },

    seeOrderDetails(data) {
      this.$router.push({
        name: 'orders',
        params: {
          order: data['order_status_history_driver.order.order_number']
        }
      })
    },

    setInitialDate() {
      this.$set(this.filters.dateRange, 'init', this.dateRangeInit)
      this.$set(this.filters.dateRange, 'end', this.dateRangeEnd)
    },
  }
}
</script>

<style lang="scss" scoped>
.FinancialOnlineErrors {
  width: 100%;
  &__subheaderTab {
    display: flex;
    align-items: flex-end;
    grid-gap: 1rem;
      @media #{$mobile-view} {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
  .table {
    margin: 0 auto;
    max-width: 1180px;
    width: 100%;
    max-width: 1180px;
    padding: 0 0 3rem 0;
    @media (max-width: $viewport-lg) {
      padding: 0 1rem 3rem 1rem;
    }
  }
  &__totalErrors {
    text-align: center;
    font-size: $font-size-3xsmall;
    color: $color-neutral-stronger;
  }
}
</style>
